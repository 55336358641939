import { default as _91slug_93M6J0pW7YTmMeta } from "/home/forge/airlifeb2b.com/pages/[slug].vue?macro=true";
import { default as addressyYmW2S0wiZMeta } from "/home/forge/airlifeb2b.com/pages/account/address.vue?macro=true";
import { default as _91id_93zbiiQM6cwmMeta } from "/home/forge/airlifeb2b.com/pages/account/orders/[id].vue?macro=true";
import { default as _91id_933YEsJE5XOqMeta } from "/home/forge/airlifeb2b.com/pages/account/orders/cancel/[id].vue?macro=true";
import { default as index9f2WsNj81GMeta } from "/home/forge/airlifeb2b.com/pages/account/orders/index.vue?macro=true";
import { default as reviewjPnAMbHRYkMeta } from "/home/forge/airlifeb2b.com/pages/account/review.vue?macro=true";
import { default as _91id_932mNyxVhtVrMeta } from "/home/forge/airlifeb2b.com/pages/account/statement/detail/[id].vue?macro=true";
import { default as indexteonSDbONuMeta } from "/home/forge/airlifeb2b.com/pages/account/statement/index.vue?macro=true";
import { default as userqU5t3gUmQTMeta } from "/home/forge/airlifeb2b.com/pages/account/user.vue?macro=true";
import { default as wishlistdAPcwUw52LMeta } from "/home/forge/airlifeb2b.com/pages/account/wishlist.vue?macro=true";
import { default as cartgxCAnIT3leMeta } from "/home/forge/airlifeb2b.com/pages/cart.vue?macro=true";
import { default as indexWzogY3WK9RMeta } from "/home/forge/airlifeb2b.com/pages/checkout/index.vue?macro=true";
import { default as paymentMNr1Qooy4dMeta } from "/home/forge/airlifeb2b.com/pages/checkout/payment.vue?macro=true";
import { default as summary_46clientti0mse0u8bMeta } from "/home/forge/airlifeb2b.com/pages/checkout/summary.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/home/forge/airlifeb2b.com/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as requestg1RcwGgOhmMeta } from "/home/forge/airlifeb2b.com/pages/customer/request.vue?macro=true";
import { default as successD4Zc1EbSYuMeta } from "/home/forge/airlifeb2b.com/pages/customer/success.vue?macro=true";
import { default as iletisimnXFrvl4Jt9Meta } from "/home/forge/airlifeb2b.com/pages/iletisim.vue?macro=true";
import { default as indexNjfjfpq8yeMeta } from "/home/forge/airlifeb2b.com/pages/index.vue?macro=true";
import { default as login54Ms8mI4BGMeta } from "/home/forge/airlifeb2b.com/pages/login.vue?macro=true";
import { default as offerE0n3UyFeQ2Meta } from "/home/forge/airlifeb2b.com/pages/offer.vue?macro=true";
import { default as status3W71kOlWfbMeta } from "/home/forge/airlifeb2b.com/pages/order/status.vue?macro=true";
import { default as trackjmDdYQ7I5yMeta } from "/home/forge/airlifeb2b.com/pages/order/track.vue?macro=true";
import { default as forgotimNjH82YAmMeta } from "/home/forge/airlifeb2b.com/pages/password/forgot.vue?macro=true";
import { default as resetaeXd5G51iNMeta } from "/home/forge/airlifeb2b.com/pages/password/reset.vue?macro=true";
import { default as payment_45notification992M0lqsBmMeta } from "/home/forge/airlifeb2b.com/pages/payment-notification.vue?macro=true";
import { default as summaryeTilKuGTKIMeta } from "/home/forge/airlifeb2b.com/pages/payment/summary.vue?macro=true";
import { default as registereyDueqWdlpMeta } from "/home/forge/airlifeb2b.com/pages/register.vue?macro=true";
import { default as searchJJNFxnUU7EMeta } from "/home/forge/airlifeb2b.com/pages/search.vue?macro=true";
import { default as locationsR1lhyQrKIDMeta } from "/home/forge/airlifeb2b.com/modules/locationsPage/pages/locations.vue?macro=true";
export default [
  {
    name: "slug___en",
    path: "/:slug()",
    meta: _91slug_93M6J0pW7YTmMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/[slug].vue")
  },
  {
    name: "slug___tr",
    path: "/tr/:slug()",
    meta: _91slug_93M6J0pW7YTmMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/[slug].vue")
  },
  {
    name: "account-address___en",
    path: "/account/address",
    meta: addressyYmW2S0wiZMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/account/address.vue")
  },
  {
    name: "account-address___tr",
    path: "/tr/account/address",
    meta: addressyYmW2S0wiZMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/account/address.vue")
  },
  {
    name: "account-orders-id___en",
    path: "/account/orders/:id()",
    meta: _91id_93zbiiQM6cwmMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/account/orders/[id].vue")
  },
  {
    name: "account-orders-id___tr",
    path: "/tr/account/orders/:id()",
    meta: _91id_93zbiiQM6cwmMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/account/orders/[id].vue")
  },
  {
    name: "account-orders-cancel-id___en",
    path: "/account/orders/cancel/:id()",
    meta: _91id_933YEsJE5XOqMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/account/orders/cancel/[id].vue")
  },
  {
    name: "account-orders-cancel-id___tr",
    path: "/tr/account/orders/cancel/:id()",
    meta: _91id_933YEsJE5XOqMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/account/orders/cancel/[id].vue")
  },
  {
    name: "account-orders___en",
    path: "/account/orders",
    meta: index9f2WsNj81GMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/account/orders/index.vue")
  },
  {
    name: "account-orders___tr",
    path: "/tr/account/orders",
    meta: index9f2WsNj81GMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/account/orders/index.vue")
  },
  {
    name: "account-review___en",
    path: "/account/review",
    meta: reviewjPnAMbHRYkMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/account/review.vue")
  },
  {
    name: "account-review___tr",
    path: "/tr/account/review",
    meta: reviewjPnAMbHRYkMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/account/review.vue")
  },
  {
    name: "account-statement-detail-id___en",
    path: "/account/statement/detail/:id()",
    meta: _91id_932mNyxVhtVrMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/account/statement/detail/[id].vue")
  },
  {
    name: "account-statement-detail-id___tr",
    path: "/tr/account/statement/detail/:id()",
    meta: _91id_932mNyxVhtVrMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/account/statement/detail/[id].vue")
  },
  {
    name: "account-statement___en",
    path: "/account/statement",
    meta: indexteonSDbONuMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/account/statement/index.vue")
  },
  {
    name: "account-statement___tr",
    path: "/tr/account/statement",
    meta: indexteonSDbONuMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/account/statement/index.vue")
  },
  {
    name: "account-user___en",
    path: "/account/user",
    meta: userqU5t3gUmQTMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/account/user.vue")
  },
  {
    name: "account-user___tr",
    path: "/tr/account/user",
    meta: userqU5t3gUmQTMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/account/user.vue")
  },
  {
    name: "account-wishlist___en",
    path: "/account/wishlist",
    meta: wishlistdAPcwUw52LMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/account/wishlist.vue")
  },
  {
    name: "account-wishlist___tr",
    path: "/tr/account/wishlist",
    meta: wishlistdAPcwUw52LMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/account/wishlist.vue")
  },
  {
    name: "cart___en",
    path: "/cart",
    component: () => import("/home/forge/airlifeb2b.com/pages/cart.vue")
  },
  {
    name: "cart___tr",
    path: "/tr/cart",
    component: () => import("/home/forge/airlifeb2b.com/pages/cart.vue")
  },
  {
    name: "checkout___en",
    path: "/checkout",
    meta: indexWzogY3WK9RMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/checkout/index.vue")
  },
  {
    name: "checkout___tr",
    path: "/tr/checkout",
    meta: indexWzogY3WK9RMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/checkout/index.vue")
  },
  {
    name: "checkout-payment___en",
    path: "/checkout/payment",
    meta: paymentMNr1Qooy4dMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/checkout/payment.vue")
  },
  {
    name: "checkout-payment___tr",
    path: "/tr/checkout/payment",
    meta: paymentMNr1Qooy4dMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/checkout/payment.vue")
  },
  {
    name: "checkout-summary___en",
    path: "/checkout/summary",
    component: () => createClientPage(() => import("/home/forge/airlifeb2b.com/pages/checkout/summary.client.vue"))
  },
  {
    name: "checkout-summary___tr",
    path: "/tr/checkout/summary",
    component: () => createClientPage(() => import("/home/forge/airlifeb2b.com/pages/checkout/summary.client.vue"))
  },
  {
    name: "customer-request___en",
    path: "/customer/request",
    component: () => import("/home/forge/airlifeb2b.com/pages/customer/request.vue")
  },
  {
    name: "customer-request___tr",
    path: "/tr/customer/request",
    component: () => import("/home/forge/airlifeb2b.com/pages/customer/request.vue")
  },
  {
    name: "customer-success___en",
    path: "/customer/success",
    component: () => import("/home/forge/airlifeb2b.com/pages/customer/success.vue")
  },
  {
    name: "customer-success___tr",
    path: "/tr/customer/success",
    component: () => import("/home/forge/airlifeb2b.com/pages/customer/success.vue")
  },
  {
    name: "iletisim___en",
    path: "/iletisim",
    component: () => import("/home/forge/airlifeb2b.com/pages/iletisim.vue")
  },
  {
    name: "iletisim___tr",
    path: "/tr/iletisim",
    component: () => import("/home/forge/airlifeb2b.com/pages/iletisim.vue")
  },
  {
    name: "index___en",
    path: "/",
    meta: indexNjfjfpq8yeMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/index.vue")
  },
  {
    name: "index___tr",
    path: "/tr",
    meta: indexNjfjfpq8yeMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/index.vue")
  },
  {
    name: "login___en",
    path: "/login",
    meta: login54Ms8mI4BGMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/login.vue")
  },
  {
    name: "login___tr",
    path: "/tr/login",
    meta: login54Ms8mI4BGMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/login.vue")
  },
  {
    name: "offer___en",
    path: "/offer",
    meta: offerE0n3UyFeQ2Meta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/offer.vue")
  },
  {
    name: "offer___tr",
    path: "/tr/offer",
    meta: offerE0n3UyFeQ2Meta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/offer.vue")
  },
  {
    name: "order-status___en",
    path: "/order/status",
    component: () => import("/home/forge/airlifeb2b.com/pages/order/status.vue")
  },
  {
    name: "order-status___tr",
    path: "/tr/order/status",
    component: () => import("/home/forge/airlifeb2b.com/pages/order/status.vue")
  },
  {
    name: "order-track___en",
    path: "/order/track",
    component: () => import("/home/forge/airlifeb2b.com/pages/order/track.vue")
  },
  {
    name: "order-track___tr",
    path: "/tr/order/track",
    component: () => import("/home/forge/airlifeb2b.com/pages/order/track.vue")
  },
  {
    name: "password-forgot___en",
    path: "/password/forgot",
    meta: forgotimNjH82YAmMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/password/forgot.vue")
  },
  {
    name: "password-forgot___tr",
    path: "/tr/password/forgot",
    meta: forgotimNjH82YAmMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/password/forgot.vue")
  },
  {
    name: "password-reset___en",
    path: "/password/reset",
    meta: resetaeXd5G51iNMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/password/reset.vue")
  },
  {
    name: "password-reset___tr",
    path: "/tr/password/reset",
    meta: resetaeXd5G51iNMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/password/reset.vue")
  },
  {
    name: "payment-notification___en",
    path: "/payment-notification",
    component: () => import("/home/forge/airlifeb2b.com/pages/payment-notification.vue")
  },
  {
    name: "payment-notification___tr",
    path: "/tr/payment-notification",
    component: () => import("/home/forge/airlifeb2b.com/pages/payment-notification.vue")
  },
  {
    name: "payment-summary___en",
    path: "/payment/summary",
    component: () => import("/home/forge/airlifeb2b.com/pages/payment/summary.vue")
  },
  {
    name: "payment-summary___tr",
    path: "/tr/payment/summary",
    component: () => import("/home/forge/airlifeb2b.com/pages/payment/summary.vue")
  },
  {
    name: "register___en",
    path: "/register",
    meta: registereyDueqWdlpMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/register.vue")
  },
  {
    name: "register___tr",
    path: "/tr/register",
    meta: registereyDueqWdlpMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/register.vue")
  },
  {
    name: "search___en",
    path: "/search",
    meta: searchJJNFxnUU7EMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/search.vue")
  },
  {
    name: "search___tr",
    path: "/tr/search",
    meta: searchJJNFxnUU7EMeta || {},
    component: () => import("/home/forge/airlifeb2b.com/pages/search.vue")
  },
  {
    name: "contact___en",
    path: "/magazalarimiz",
    component: () => import("/home/forge/airlifeb2b.com/modules/locationsPage/pages/locations.vue")
  },
  {
    name: "contact___tr",
    path: "/tr/magazalarimiz",
    component: () => import("/home/forge/airlifeb2b.com/modules/locationsPage/pages/locations.vue")
  }
]